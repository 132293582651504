import Logo from "./img/logo.png";
import BannerLeft from "./img/banner-img-02.png";
import BannerRight from "./img/banner-img-01.png";
import Nft01 from "./img/nft-01.png";
import Nft02 from "./img/nft-02.png";
import Nft03 from "./img/nft-03.png";
import { FaTwitter, FaInstagram, FaDiscord, FaFacebook } from "react-icons/fa";
import { BsReddit } from "react-icons/bs";
import { BsTelegram } from "react-icons/bs";
import { HiArrowUp } from "react-icons/hi";
import ImgSlider from "./components/slider/ImgSlider";
import sharing from "./img/sharing.png";
import banner from "./img/banner.png";
import Web3 from 'web3';
import { useEffect, useState} from 'react'
// import CounterInput from "react-counter-input"
import { mintNft, loadTotalMintCount } from "./interact.js"


const Home = () => {
    const [buyAmount, setBuyAmount] = useState(1);
    // const [nftPrice, setNftPrice] = useState(3);
    const [metamaskConnected, setMetamaskConnnected] = useState(false);
    const [account, setAccount] = useState();
    const [networkId, setNetworkId] = useState();
    const [isMetamask, setIsMetamask] = useState(true);
    const [totalMintCount, setTotalMintCount] = useState(0);
    useEffect(async () => {
        await loadWeb3().then((data) => {
            if (data !== false) {
                loadBlockchainData();
            }
        });
        // handleConnectWallet();
        setTotalMintCount(await loadTotalMintCount());
      
    }, []);

    const loadWeb3 = async () => {
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum);
        } else if (window.web3) {
            window.web3 = new Web3(window.web3.currentProvider);
        } else {
            setIsMetamask(false);
            return false;
        }
    }

    const loadBlockchainData = async () => {
        const web3 = window.web3;
        const accounts = await web3.eth.getAccounts();
        const networkId = await web3.eth.net.getId();
        setNetworkId(networkId);

        if (accounts.length == 0) {
            setMetamaskConnnected(false);
        } else {
            setMetamaskConnnected(true);
            setAccount(accounts[0]);
        }

        window.ethereum.on("accountsChanged", (accounts) => {
            if (accounts.length > 0) setAccount(accounts[0]);
            else setAccount();
        });
        window.ethereum.on("networkChanged", (networkId) => {
            setNetworkId(networkId);
        });
    };
    const changeNumber = (e) => {
        setBuyAmount(e)
    }
    const handleConnectWallet = async () => {

        if (window.ethereum) {
            await window.ethereum.enable();
            setMetamaskConnnected(true);
        }
    };
    const MintPressed = async () => {
       var price;
        const tier1_count = 3100;
        const tier2_count = 4900;
        const first_price = 0.2;
        const second_price = 0.25;
        const third_price = 0.3;
        if(totalMintCount < tier1_count){
            if(buyAmount <= (tier1_count - totalMintCount)){price = first_price * buyAmount;}
            else {price  = first_price * (tier1_count - totalMintCount) + second_price *(buyAmount - (tier1_count - totalMintCount));}

        }
        if((totalMintCount >= tier1_count) && (totalMintCount < tier2_count)) {
            if(buyAmount <= (tier2_count - totalMintCount)) {price = second_price * buyAmount;}
            else{price = second_price * (tier2_count - totalMintCount) + third_price * (buyAmount - (tier2_count - totalMintCount))}

        }
        if(totalMintCount >= tier2_count) {price = third_price * buyAmount;}

        console.log("value are ===",  price.toString(), buyAmount, account);
        await mintNft(price.toString(), buyAmount, account);

    }
    const backToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };
    return (
        <>
           
            <header className="main-header-box">
                <div className="main-container">
                    <div className="main-header">
                        <div className="main-logo">
                            <img src={Logo} alt="logo" />
                        </div>
                        <div className="main-nav">
                            <a href="#home" className="active">
                                Home
                            </a>
                            <a href="#nft">Nft Intro</a>
                            <a href="#about">About Us</a>
                        </div>
                    </div>
                </div>
            </header>

            <section className="main-banner" id="home">
                <div className="banner-content">
                    <div className="banner-head">
                        <img src={banner} alt="banner text" />
                        <p>WEALTH SHARING NFT’s. THE NFT WITH UTILITY</p>
                    </div>
                    <div className="banner-wallet">
                        <h2>{totalMintCount}/6655</h2>
                        <p>{account}</p>
                        <div className="cost-area">
                            <h4>WAPES Costs:</h4>
                            <div style={{textAlign: "left"}}>
                                <p>0.2 BNB for the first 3100</p>
                                <p>0.25 BNB for the next 1800</p>
                                <p>0.3 BNB for the last 1755</p>
                            </div>
                        </div>
                        
                        <p style={{fontSize: '20px'}}>Excluding Gas fees</p>
                        {metamaskConnected && account ? (
                   <>     
                   <input className="inputCount" type="number"  min="1" max="20" value={buyAmount} onChange={(e) => changeNumber(e.target.value)}/>
                  
                   {networkId !=56 ? (
                       <button disabled>Mint</button>
                    //    <button onClick={MintPressed}>Mint</button>
                   ) : (<button onClick={MintPressed}>Mint</button>) 
                   }
                    </>
               
                ) : (
                    <button type="button" onClick={() => handleConnectWallet()}>
                        connect
                    </button>
                )}
                        <p>Connect to Binance Smart Chain Network </p>
                    </div>
                    <div className="banner-bottom">
                        <h4>We Welcome You To Our Communities</h4>
                        {/* 
                         */}
                        <div className="footer-social" style={{ marginBottom: '10px' }}>
                        <a href="https://twitter.com/WealthProtocol?t=qFlr7bZzh235I2lRomIJPA&s=09" target="_blank">
                                <FaTwitter />
                            </a>
                            <a href="https://discord.gg/7vzC4uatZS" target="_blank">
                                <FaDiscord />
                            </a>
                            <a href="https://www.facebook.com/Wealthprotocol" target="_blank">
                                <FaFacebook />
                            </a>
                            <a href="http://instagram.com/wealthprotocol.io" target="_blank">
                                <FaInstagram />
                            </a>
                            <a href="https://www.reddit.com/r/WealthProtocol?utm_medium=android_app&utm_source=share" target="_blank">
                                <BsReddit />
                            </a>
                            <a href="https://t.me/WealthProtocol" target="_blank">
                                <BsTelegram />
                            </a>
                        </div>

                        <p>
                            <span>
                            WEALTH SHARING NFT’s - 5% OF THE ROYALTY FEE REWARDS THE NFT HOLDERS. 
                            </span>
                            &nbsp;&nbsp;Please make sure you are connected to the right network (Binance Smart Chain Mainnet) and the correct address.<br/>Please note: Once you make the purchase, you cannot undo this action. 
                        </p>
                    </div>
                </div>
                <img
                    className="banner-img-left"
                    src={BannerLeft}
                    alt="bannerimage"
                />
                <img
                    className="banner-img-right"
                    src={BannerRight}
                    alt="bannerimage"
                />
            </section>

            <ImgSlider />

            <section className="nft-section" id="nft">
                <div className="main-container">
                    <div className="nft-content">
                        <div className="nft-left">
                            <img src={sharing} alt="sharing" />
                            <p>
                                <b style={{ fontSize: '26px' }}>  The NFT With Utility! &nbsp;</b> ALL NFT's &nbsp;will include Wealth Sharing regardless of the rarity. 6% total Royalty fee will be collected with which 5% of Royalties will be distributed to the holders of the NFT's free of any fees. The remaining 1% will be used to buy and burn Wealth Protocol Tokens. 5% Royalties paid in bnb (bep20) will be distributed to the NFT holders. The secondary utility will be based on rarities for the Tier Levels to provide access to Wealth Protocol’s Ecosystem. The first level will provide the investors with access to the base tier. The intermediate level will give access to the second tier. The third level will give access to the top tier of Wealth Protocol’s Ecosystem
                            </p>
                        </div>
                        <img src={Nft01} alt="nft 1" />
                    </div>
                    <div className="nft-content">
                        <img src={Nft02} alt="nft 2" />
                        <div className="nft-left">
                            <p>
                                <span style={{ fontSize: '26px' }}>Wealthy Apes NFT Collection.&nbsp;</span>&nbsp;A collection brought to you by Wealth Protocol.  6655 Wealthy Ape NFT s that are digital collectibles with utility which will be released on the Binance Smart Chain with the Ethereum blockchain to follow. The first release of Wealthy Apes will be the collection of 5555 for the Binance Smart Chain to be released to the public. The remaining 1100 will be minted and given out to the community, private/presale holders and for giveaways or contests.
                            </p>
                         
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-box" id="about">
                <div className="main-container">
                    <div className="about-content">
                        <div className="about-left">
                            <h2>About Us</h2>
                            <p>
                                Wealth Protocol is the world's first ever,
                                comprehensive Crypto-Wealth Management
                                application that makes investing in crypto
                                simple, safe, and convenient. Wealth Wallet can
                                help you save money and stay tax compliant.
                                There are a variety of tax forms which may apply
                                for your cryptocurrency tax filing depending on
                                your tax residency as well as other factors.{" "}
                            </p>
                            <p>
                                Wealth wallet can automatically perform these accounting calculations for you to minimize your cryptocurrency capital gains and by utilizing tax loss harvesting transactions which allow you to realize capital losses, enable you to save on your tax bill. Our Wealth Wallet app empowers users to track their cryptocurrency investment performances and provide detailed reporting to assist in staying tax compliant.{" "}
                            </p>
                            <p>
                                Keep an eye on your portfolio, no matter where you are. The utilities will provide the investor with access to our hybrid tier system for the Wealth Protocol Platform comprising of the Wealth Wallet.
                            </p>
                        </div>
                        <img src={Nft03} alt="about image" />
                    </div>
                </div>
            </section>

            <section>
                <div className="main-container">
                    <div className="about-text">
                        <p>
                        WEALTH SHARING NFT's - 6% TOTAL ROYALTY FEE WILL BE COLLECTED WITH WHICH 5% OF ROYALTIES WILL BE DISTRIBUTED TO THE HOLDERS OF THE NFT'S FREE OF ANY FEES. THE REMAINING 1% WILL BE USED TO BUY AND BURN WEALTH PROTOCOL TOKENS.


                        </p>
                    </div>
                </div>
            </section>

            <section className="footer-head-box">
                <div className="main-container">
                    <div className="footer-head">
                        <h4>
                            ETH NFT's coming soon! brought to you by{" "}
                            <h2>WEALTH PROTOCOL</h2>
                        </h4>
                    </div>
                </div>
            </section>

            <footer className="footer-box">
                <div className="main-container">
                    <div className="footer-content">
                        <div className="footer-social">
                            <a href="#">
                                <FaTwitter />
                            </a>
                            <a href="#">
                                <FaDiscord />
                            </a>
                            <a href="#">
                                <FaFacebook />
                            </a>
                            <a href="#">
                                <FaInstagram />
                            </a>
                            <a href="#">
                                <BsReddit />
                            </a>
                            <a href="#">
                                <BsTelegram />
                            </a>
                        </div>
                        <div className="footer-link">
                            <ul>
                                <li>
                                    Copyright © 2022 Wealth Protocol{" "}
                                    <a href="#"  style={{color:'#f70fff'}}>wealthprotocol.io</a>
                                </li>
                                <li>
                                    Send us email{" "}
                                    <a href="#"  style={{color:'#f70fff'}}>admin@wealthprotocol.io</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="back-top">
                        <a href="javascript:void(0)" onClick={backToTop}>
                            <HiArrowUp />
                        </a>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Home;
